import { CfmRepositoryServicePromiseClient } from '../grpc/generated/cfmRepository_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new CfmRepositoryServicePromiseClient(apiUrl);

async function consultRegistryName(request) {
  const response = await svc.consultRegistryName(request, {});
  // console.log(response.toObject());
  return response.toObject();
}

async function consultRegistryUF(request) {
  const response = await svc.consultRegistryUF(request, {});
  return response.toObject();
}

export default {
  consultRegistryName,
  consultRegistryUF
}