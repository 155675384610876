// source: cfmRepository.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.cfmRepositoryApi.Actuation', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Council', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Empty', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Registry', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.RegistryParams', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.RegistryResponse', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Speciality', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Status', null, global);
goog.exportSymbol('proto.cfmRepositoryApi.Uf', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Empty.displayName = 'proto.cfmRepositoryApi.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Status.displayName = 'proto.cfmRepositoryApi.Status';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Uf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.Uf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Uf.displayName = 'proto.cfmRepositoryApi.Uf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Registry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.Registry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Registry.displayName = 'proto.cfmRepositoryApi.Registry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.RegistryParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.RegistryParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.RegistryParams.displayName = 'proto.cfmRepositoryApi.RegistryParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Actuation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.Actuation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Actuation.displayName = 'proto.cfmRepositoryApi.Actuation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Speciality = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cfmRepositoryApi.Speciality.repeatedFields_, null);
};
goog.inherits(proto.cfmRepositoryApi.Speciality, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Speciality.displayName = 'proto.cfmRepositoryApi.Speciality';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.Council = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cfmRepositoryApi.Council.repeatedFields_, null);
};
goog.inherits(proto.cfmRepositoryApi.Council, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.Council.displayName = 'proto.cfmRepositoryApi.Council';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cfmRepositoryApi.RegistryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cfmRepositoryApi.RegistryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cfmRepositoryApi.RegistryResponse.displayName = 'proto.cfmRepositoryApi.RegistryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Empty}
 */
proto.cfmRepositoryApi.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Empty;
  return proto.cfmRepositoryApi.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Empty}
 */
proto.cfmRepositoryApi.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Status}
 */
proto.cfmRepositoryApi.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Status;
  return proto.cfmRepositoryApi.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Status}
 */
proto.cfmRepositoryApi.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.cfmRepositoryApi.Status.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cfmRepositoryApi.Status} returns this
 */
proto.cfmRepositoryApi.Status.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Uf.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Uf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Uf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Uf.toObject = function(includeInstance, msg) {
  var f, obj = {
    uf: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Uf}
 */
proto.cfmRepositoryApi.Uf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Uf;
  return proto.cfmRepositoryApi.Uf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Uf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Uf}
 */
proto.cfmRepositoryApi.Uf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Uf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Uf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Uf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Uf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uf = 1;
 * @return {string}
 */
proto.cfmRepositoryApi.Uf.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Uf} returns this
 */
proto.cfmRepositoryApi.Uf.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Registry.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Registry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Registry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Registry.toObject = function(includeInstance, msg) {
  var f, obj = {
    registry: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Registry}
 */
proto.cfmRepositoryApi.Registry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Registry;
  return proto.cfmRepositoryApi.Registry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Registry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Registry}
 */
proto.cfmRepositoryApi.Registry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegistry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Registry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Registry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Registry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Registry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistry();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 registry = 1;
 * @return {number}
 */
proto.cfmRepositoryApi.Registry.prototype.getRegistry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.Registry} returns this
 */
proto.cfmRepositoryApi.Registry.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.RegistryParams.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.RegistryParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.RegistryParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.RegistryParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    registry: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uf: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.RegistryParams}
 */
proto.cfmRepositoryApi.RegistryParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.RegistryParams;
  return proto.cfmRepositoryApi.RegistryParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.RegistryParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.RegistryParams}
 */
proto.cfmRepositoryApi.RegistryParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegistry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.RegistryParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.RegistryParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.RegistryParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.RegistryParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistry();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 registry = 1;
 * @return {number}
 */
proto.cfmRepositoryApi.RegistryParams.prototype.getRegistry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.RegistryParams} returns this
 */
proto.cfmRepositoryApi.RegistryParams.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uf = 2;
 * @return {string}
 */
proto.cfmRepositoryApi.RegistryParams.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.RegistryParams} returns this
 */
proto.cfmRepositoryApi.RegistryParams.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cfmRepositoryApi.RegistryParams.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.RegistryParams} returns this
 */
proto.cfmRepositoryApi.RegistryParams.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Actuation.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Actuation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Actuation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Actuation.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    exclusive: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Actuation}
 */
proto.cfmRepositoryApi.Actuation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Actuation;
  return proto.cfmRepositoryApi.Actuation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Actuation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Actuation}
 */
proto.cfmRepositoryApi.Actuation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRqeNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExclusive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Actuation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Actuation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Actuation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Actuation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqeNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExclusive();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 rqe_number = 1;
 * @return {number}
 */
proto.cfmRepositoryApi.Actuation.prototype.getRqeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.Actuation} returns this
 */
proto.cfmRepositoryApi.Actuation.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cfmRepositoryApi.Actuation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Actuation} returns this
 */
proto.cfmRepositoryApi.Actuation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 exclusive = 3;
 * @return {number}
 */
proto.cfmRepositoryApi.Actuation.prototype.getExclusive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.Actuation} returns this
 */
proto.cfmRepositoryApi.Actuation.prototype.setExclusive = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cfmRepositoryApi.Speciality.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Speciality.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Speciality.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Speciality} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Speciality.toObject = function(includeInstance, msg) {
  var f, obj = {
    rqeNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actuationList: jspb.Message.toObjectList(msg.getActuationList(),
    proto.cfmRepositoryApi.Actuation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Speciality}
 */
proto.cfmRepositoryApi.Speciality.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Speciality;
  return proto.cfmRepositoryApi.Speciality.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Speciality} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Speciality}
 */
proto.cfmRepositoryApi.Speciality.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRqeNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.cfmRepositoryApi.Actuation;
      reader.readMessage(value,proto.cfmRepositoryApi.Actuation.deserializeBinaryFromReader);
      msg.addActuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Speciality.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Speciality.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Speciality} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Speciality.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRqeNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActuationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.cfmRepositoryApi.Actuation.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 rqe_number = 1;
 * @return {number}
 */
proto.cfmRepositoryApi.Speciality.prototype.getRqeNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.Speciality} returns this
 */
proto.cfmRepositoryApi.Speciality.prototype.setRqeNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.cfmRepositoryApi.Speciality.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Speciality} returns this
 */
proto.cfmRepositoryApi.Speciality.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Actuation actuation = 3;
 * @return {!Array<!proto.cfmRepositoryApi.Actuation>}
 */
proto.cfmRepositoryApi.Speciality.prototype.getActuationList = function() {
  return /** @type{!Array<!proto.cfmRepositoryApi.Actuation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cfmRepositoryApi.Actuation, 3));
};


/**
 * @param {!Array<!proto.cfmRepositoryApi.Actuation>} value
 * @return {!proto.cfmRepositoryApi.Speciality} returns this
*/
proto.cfmRepositoryApi.Speciality.prototype.setActuationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.cfmRepositoryApi.Actuation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cfmRepositoryApi.Actuation}
 */
proto.cfmRepositoryApi.Speciality.prototype.addActuation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.cfmRepositoryApi.Actuation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cfmRepositoryApi.Speciality} returns this
 */
proto.cfmRepositoryApi.Speciality.prototype.clearActuationList = function() {
  return this.setActuationList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cfmRepositoryApi.Council.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.Council.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.Council.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.Council} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Council.toObject = function(includeInstance, msg) {
  var f, obj = {
    registry: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uf: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subscriptionType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    specialityList: jspb.Message.toObjectList(msg.getSpecialityList(),
    proto.cfmRepositoryApi.Speciality.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.Council}
 */
proto.cfmRepositoryApi.Council.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.Council;
  return proto.cfmRepositoryApi.Council.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.Council} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.Council}
 */
proto.cfmRepositoryApi.Council.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegistry(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUf(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptionType(value);
      break;
    case 5:
      var value = new proto.cfmRepositoryApi.Speciality;
      reader.readMessage(value,proto.cfmRepositoryApi.Speciality.deserializeBinaryFromReader);
      msg.addSpeciality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.Council.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.Council.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.Council} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.Council.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegistry();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUf();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubscriptionType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSpecialityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.cfmRepositoryApi.Speciality.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 registry = 1;
 * @return {number}
 */
proto.cfmRepositoryApi.Council.prototype.getRegistry = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cfmRepositoryApi.Council} returns this
 */
proto.cfmRepositoryApi.Council.prototype.setRegistry = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string uf = 2;
 * @return {string}
 */
proto.cfmRepositoryApi.Council.prototype.getUf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Council} returns this
 */
proto.cfmRepositoryApi.Council.prototype.setUf = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cfmRepositoryApi.Council.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Council} returns this
 */
proto.cfmRepositoryApi.Council.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subscription_type = 4;
 * @return {string}
 */
proto.cfmRepositoryApi.Council.prototype.getSubscriptionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.Council} returns this
 */
proto.cfmRepositoryApi.Council.prototype.setSubscriptionType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Speciality speciality = 5;
 * @return {!Array<!proto.cfmRepositoryApi.Speciality>}
 */
proto.cfmRepositoryApi.Council.prototype.getSpecialityList = function() {
  return /** @type{!Array<!proto.cfmRepositoryApi.Speciality>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cfmRepositoryApi.Speciality, 5));
};


/**
 * @param {!Array<!proto.cfmRepositoryApi.Speciality>} value
 * @return {!proto.cfmRepositoryApi.Council} returns this
*/
proto.cfmRepositoryApi.Council.prototype.setSpecialityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.cfmRepositoryApi.Speciality=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cfmRepositoryApi.Speciality}
 */
proto.cfmRepositoryApi.Council.prototype.addSpeciality = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.cfmRepositoryApi.Speciality, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cfmRepositoryApi.Council} returns this
 */
proto.cfmRepositoryApi.Council.prototype.clearSpecialityList = function() {
  return this.setSpecialityList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cfmRepositoryApi.RegistryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cfmRepositoryApi.RegistryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.RegistryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errormessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    council: (f = msg.getCouncil()) && proto.cfmRepositoryApi.Council.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cfmRepositoryApi.RegistryResponse}
 */
proto.cfmRepositoryApi.RegistryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cfmRepositoryApi.RegistryResponse;
  return proto.cfmRepositoryApi.RegistryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cfmRepositoryApi.RegistryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cfmRepositoryApi.RegistryResponse}
 */
proto.cfmRepositoryApi.RegistryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrormessage(value);
      break;
    case 3:
      var value = new proto.cfmRepositoryApi.Council;
      reader.readMessage(value,proto.cfmRepositoryApi.Council.deserializeBinaryFromReader);
      msg.setCouncil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cfmRepositoryApi.RegistryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cfmRepositoryApi.RegistryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cfmRepositoryApi.RegistryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrormessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCouncil();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cfmRepositoryApi.Council.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cfmRepositoryApi.RegistryResponse} returns this
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string errorMessage = 2;
 * @return {string}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.getErrormessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cfmRepositoryApi.RegistryResponse} returns this
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.setErrormessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Council council = 3;
 * @return {?proto.cfmRepositoryApi.Council}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.getCouncil = function() {
  return /** @type{?proto.cfmRepositoryApi.Council} */ (
    jspb.Message.getWrapperField(this, proto.cfmRepositoryApi.Council, 3));
};


/**
 * @param {?proto.cfmRepositoryApi.Council|undefined} value
 * @return {!proto.cfmRepositoryApi.RegistryResponse} returns this
*/
proto.cfmRepositoryApi.RegistryResponse.prototype.setCouncil = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cfmRepositoryApi.RegistryResponse} returns this
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.clearCouncil = function() {
  return this.setCouncil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cfmRepositoryApi.RegistryResponse.prototype.hasCouncil = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.cfmRepositoryApi);
