export const cr = [
  { name: 'Conselho Regional de Medicina', code: 'CRM', typeRegistry: '9' },
  { name: 'Conselho Regional de Psicologia', code: 'CRP', typeRegistry: '72' },
  { name: 'Conselho Regional de Odontologia', code: 'CRO', typeRegistry: '77' },
  { name: 'Conselho Regional de Fisioterapia e Terapia Ocupacional', code: 'CREFITO', typeRegistry: '70' },
  { name: 'Conselho Regional de Fonoaudiologia', code: 'CREFONO', typeRegistry: '8' },
  { name: 'Conselho Regional de Nutrição', code: 'CRN', typeRegistry: '13' },
  { name: 'Conselho Regional de Educação Física', code: 'CREF', typeRegistry: '6' },
  { name: 'Conselho Regional de Enfermagem', code: 'COREN', typeRegistry: '48' },
  { name: 'Minha atividade não exige conselho', code: 'OUTRO', typeRegistry: '53' },
]
