/**
 * @fileoverview gRPC-Web generated client stub for cfmRepositoryApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.19.1
// source: cfmRepository.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.cfmRepositoryApi = require('./cfmRepository_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.cfmRepositoryApi.CfmRepositoryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.cfmRepositoryApi.CfmRepositoryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cfmRepositoryApi.Empty,
 *   !proto.cfmRepositoryApi.Status>}
 */
const methodDescriptor_CfmRepositoryService_PopulateCfm = new grpc.web.MethodDescriptor(
  '/cfmRepositoryApi.CfmRepositoryService/PopulateCfm',
  grpc.web.MethodType.UNARY,
  proto.cfmRepositoryApi.Empty,
  proto.cfmRepositoryApi.Status,
  /**
   * @param {!proto.cfmRepositoryApi.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cfmRepositoryApi.Status.deserializeBinary
);


/**
 * @param {!proto.cfmRepositoryApi.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cfmRepositoryApi.Status)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cfmRepositoryApi.Status>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cfmRepositoryApi.CfmRepositoryServiceClient.prototype.populateCfm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/PopulateCfm',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_PopulateCfm,
      callback);
};


/**
 * @param {!proto.cfmRepositoryApi.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cfmRepositoryApi.Status>}
 *     Promise that resolves to the response
 */
proto.cfmRepositoryApi.CfmRepositoryServicePromiseClient.prototype.populateCfm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/PopulateCfm',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_PopulateCfm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cfmRepositoryApi.Uf,
 *   !proto.cfmRepositoryApi.Registry>}
 */
const methodDescriptor_CfmRepositoryService_ConsultRegistryUF = new grpc.web.MethodDescriptor(
  '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryUF',
  grpc.web.MethodType.UNARY,
  proto.cfmRepositoryApi.Uf,
  proto.cfmRepositoryApi.Registry,
  /**
   * @param {!proto.cfmRepositoryApi.Uf} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cfmRepositoryApi.Registry.deserializeBinary
);


/**
 * @param {!proto.cfmRepositoryApi.Uf} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cfmRepositoryApi.Registry)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cfmRepositoryApi.Registry>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cfmRepositoryApi.CfmRepositoryServiceClient.prototype.consultRegistryUF =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryUF',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_ConsultRegistryUF,
      callback);
};


/**
 * @param {!proto.cfmRepositoryApi.Uf} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cfmRepositoryApi.Registry>}
 *     Promise that resolves to the response
 */
proto.cfmRepositoryApi.CfmRepositoryServicePromiseClient.prototype.consultRegistryUF =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryUF',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_ConsultRegistryUF);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.cfmRepositoryApi.RegistryParams,
 *   !proto.cfmRepositoryApi.RegistryResponse>}
 */
const methodDescriptor_CfmRepositoryService_ConsultRegistryName = new grpc.web.MethodDescriptor(
  '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryName',
  grpc.web.MethodType.UNARY,
  proto.cfmRepositoryApi.RegistryParams,
  proto.cfmRepositoryApi.RegistryResponse,
  /**
   * @param {!proto.cfmRepositoryApi.RegistryParams} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.cfmRepositoryApi.RegistryResponse.deserializeBinary
);


/**
 * @param {!proto.cfmRepositoryApi.RegistryParams} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.cfmRepositoryApi.RegistryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.cfmRepositoryApi.RegistryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.cfmRepositoryApi.CfmRepositoryServiceClient.prototype.consultRegistryName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryName',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_ConsultRegistryName,
      callback);
};


/**
 * @param {!proto.cfmRepositoryApi.RegistryParams} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.cfmRepositoryApi.RegistryResponse>}
 *     Promise that resolves to the response
 */
proto.cfmRepositoryApi.CfmRepositoryServicePromiseClient.prototype.consultRegistryName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/cfmRepositoryApi.CfmRepositoryService/ConsultRegistryName',
      request,
      metadata || {},
      methodDescriptor_CfmRepositoryService_ConsultRegistryName);
};


module.exports = proto.cfmRepositoryApi;

