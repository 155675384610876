// import { LoginRequest, LoginResponse } from '../grpc/generated/professionalAccount_pb';
import { ProfessionalAccountProtoServicePromiseClient } from '../grpc/generated/professionalAccount_grpc_web_pb';
// import store from '../../../store';

// const SimpleUnaryInterceptor = function() {};

// /** @override */
// SimpleUnaryInterceptor.prototype.intercept = function(request, invoker) {
//   // Update the request message before the RPC.
//   const reqMsg = request.getRequestMessage();
//   reqMsg.setMessage('[Intercept request]' + reqMsg.getMessage());

//   // After the RPC returns successfully, update the response.
//   return invoker(request).then((response) => {
//     // You can also do something with response metadata here.
//     console.log(response.getMetadata());

//     // Update the response message.
//     const responseMsg = response.getResponseMessage();
//     responseMsg.setMessage('[Intercept response]' + responseMsg.getMessage());

//     return response;
//   });
// };

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new ProfessionalAccountProtoServicePromiseClient(apiUrl);

async function login(request) {
    const response = await svc.login(request, {});
    return response.toObject();
}

async function verifyEmail(request) {
    const response = await svc.verifyEmail(request, {});
    // console.log(response);
    return response.toObject();
}

async function sendEmailConfirmation(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.sendEmailConfirmation(request, metadata, {});
    // console.log(response);
    return response.toObject();
}

async function sendPhoneConfirmation(request) {
    const response = await svc.sendPhoneConfirmation(request, {});
    // console.log(response);
    return response.toObject();
}

async function sendPasswordRecoveryCodeToEmail(request) {
    const response = await svc.sendPasswordRecoveryCodeToEmail(request, {});
    // console.log(response);
    return response.toObject();
}

// async function sendEmailAfterUserCloseBrowser(request) {
//     const response = await svc.sendEmailAfterUserCloseBrowser(request, {});
//     return response.toObject();
// }

async function sendPasswordRecoveryCodeToUser(request) {
    const response = await svc.sendPasswordRecoveryCodeToUser(request, {});
    return response.toObject();
}

async function verifyPhone(request) {
    const response = await svc.verifyPhone(request, {});
    // console.log(response);
    return response.toObject();
}

async function verifyPasswordRecoveryCode(request) {
    const response = await svc.verifyPasswordRecoveryCode(request, {});
    return response.toObject();
}

async function findUserAccount(request) {
    const response = await svc.findUserAccount(request, {});
    // console.log(response);
    return response.toObject();
}

async function updatePhone(request) {
    const token = window.localStorage.getItem("token");
  const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updatePhone(request, metadata, {});
    // console.log(response);
    return response.toObject();
}

async function updateEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.updateEmail(request, metadata, {});
    return response.toObject();
}

async function updatePassword(request) {
    const response = await svc.updatePassword(request, {});
    return response.toObject();
}

async function verifyContinueRegister(request) {
    const response = await svc.verifyContinueRegister(request, {});
    return response.toObject();
}

async function sendEmailOnClose(request) {
    const response = await svc.sendEmailAfterUserCloseBrowser(request, {});
    return response.toObject();
}

async function getInfoProfessional(request) {
    const response = await svc.getInfoProfessional(request, {});
    return response.toObject();
}

async function saveInfo(request) {
    const response = await svc.saveInfoAfterUserCloseBrowserPageOne(request, {});
    return response.toObject();
}

async function confirmExchangeEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.confirmExchangeEmail(request, metadata, {});
    return response.toObject();
}

async function sendSMSConfirmEmail(request) {
    const token = window.localStorage.getItem("token");
    const metadata = { Authorization: "Bearer " + token };
    const response = await svc.sendSMSConfirmEmail(request, metadata, {});
    return response.toObject();
}


export default {    
    login, updatePhone, updateEmail, findUserAccount,
    getInfoProfessional, saveInfo,
    verifyEmail, sendEmailConfirmation,
    verifyPhone, sendPhoneConfirmation,
    sendPasswordRecoveryCodeToEmail,
    confirmExchangeEmail,
    sendSMSConfirmEmail,
    // sendEmailAfterUserCloseBrowser,
    sendPasswordRecoveryCodeToUser,
    verifyPasswordRecoveryCode, sendEmailOnClose,
    updatePassword, verifyContinueRegister,
}