<template>
  <router-link to="/account/login"
    ><img src="../../../assets/images/ommed_logo.svg" class="logo"
  /></router-link>
  <h5 class="mt-6 title-color" align="center">Informações Profissionais</h5>
  <div class="span-color mb-4" align="center">
    Qual o conselho que você faz parte?
    <div class="customprofile-wrapper">
      <div class="customprofile-panel">
        <div class="p-grid p-jc-center p-mt-3">
          <Dropdown
            class="mt-1 std-top selectCR"
            v-model="typeRegistry"
            :options="listCr"
            optionLabel="name"
            optionValue="code"
            @blur="clear"
            placeholder="Selecione seu conselho"
          />
          <InputText
            v-if="typeRegistry === 'OUTRO'"
            class="numberCr btop-0"
            v-model="description"
            placeholder="Descrição da profissão"
            @blur="clearError"
            :disabled="!typeRegistry"
          />
          <InputText
            v-else
            class="numberCr btop-0"
            v-model="number"
            placeholder="Número do registro"
            @blur="clearError"
            :disabled="!typeRegistry"
          />
          <Dropdown
            class="selectUF btop-0"
            v-model="uf"
            :options="listStates"
            optionLabel="name"
            optionValue="code"
            placeholder="UF"
            :disabled="!typeRegistry"
          />
        </div>
        <div v-if="errorMessage">
          <small class="p-invalid p-mt-2">
            <i class="pi pi-times-circle p-ml-1" />{{ errorMessage }}
          </small>
          <!-- <small v-if="error.name" class="p-invalid p-mt-2">
            <i class="pi pi-times-circle p-ml-1" />{{ error }}
          </small> -->
        </div>
        <!-- <div v-if="error.found || error.regular || error.active" class="p-grid p-jc-center p-mt-2">
          <small v-if="error.message" id="cr-error" class="p-error p-mr-3"><i class="pi pi-times-circle"></i> {{ error.message }} </small>
        </div> -->
        <div class="p-grid p-jc-center p-mt-3">
          <Button
            class="std-button-o-b"
            label="Incluir registro"
            @click="includeCouncil"
            :disabled="
              !(typeRegistry && number && uf) &&
              !(typeRegistry === 'OUTRO' && description && uf)
            "
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="cfmOffline">
    <div v-for="(cr, index) in crs" class="customprofile-wrapper" :key="cr.council">
      <div class="customprofile-panel cfm-card">
        <div class="p-grid p-ml-2 justify-content-between">
          <div class="p-grid mb-2 p-mt-1 p-jc-center ai-center">
            {{ cr.council }}
            <span v-if="cr.council != 'OUTRO'" style="font-weight: 700; margin: 0 5px"
              >{{ cr.number }}/{{ cr.uf }}</span
            >
            <span v-else style="font-weight: 700; margin: 0 5px"
              >{{ cr.description }} / {{ cr.uf }}</span
            >
            <!-- <span>{{ cr.uf }}</span> -->
            <!-- <span>
              <i
                v-if="cr.council == 'CRM'"
                class="pi pi-check-circle p-warning p-pt-0 p-ml-2"
                style="color: limegreen;"
                v-tooltip.right="'Registro verificado'"
              />
            </span> -->
            <!-- <span>
              <i
                class="pi pi-check-circle p-warning p-pt-0"
                style="color: gray;"
              />
            </span> -->
          </div>
          <div class="p-col-2 p-text-right p-pt-0">
            <Button
              class="p-button-icon p-button-text p-ml-auto p-pt-0"
              label="excluir"
              @click="remove(index)"
            >
              <img class="garbage-icon" src="../../../assets/images/delete.svg" />
            </Button>
          </div>
        </div>
        <!-- <hr class="line-rqe"> -->
        <div class="speciality-container">
          <div
            style="margin-bottom: 0px; width: 100%"
            v-for="(speciality, s) in cr.specialityList"
            :key="s"
          >
            <div class="p-mt-1 p-jc-center justify-content-between">
              <div class="p-mt-1 flex title-council align-items-center">
                <span class="title-council"
                  ><h6>{{ speciality.description }}</h6></span
                >
                <span
                  ><p>RQE {{ speciality.rqeNumber }}</p></span
                >
              </div>
              <div class="p-col-2 p-text-right p-pt-0">
                <Button
                  class="p-button-icon p-button-text p-ml-auto"
                  style="margin-left: 6px"
                  label="excluir"
                  @click="removeSpeciality(index, s)"
                >
                  <img class="garbage-icon" src="../../../assets/images/delete.svg" />
                </Button>
              </div>
              <!-- <hr class="line-speciality" style="margin: 5px 0 10px;"> -->
              <!-- <div class="p-mt-1 p-jc-center">
              <ul style="margin-top: 0;">
                <li v-for="(actuation, a) in cr.specialityList[s].actuationList" class="list-rqe" :key="a">
                  <span>{{ actuation.description }} <strong>RQE {{ actuation.rqeNumber }}</strong></span>
                  <hr class="line-actuation">
                </li>
              </ul>
              </div> -->
            </div>
          </div>
          <div class="p-grid p-jc-center p-mt-4 my-0 mx-auto">
            <Button
              class="std-button-o-b"
              label="incluir especialidade"
              @click="includeDialog(index)"
              v-tooltip.bottom="'incluir especialidade'"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-for="(cr, index) in crs"
      class="customprofile-wrapper"
      :key="cr.council"
    >
      <div class="customprofile-panel">
        <div class="p-grid p-mt-4 p-ml-3">
          <div class="p-col">
            <div class="p-grid">
              <div class="text-color">{{ cr.name }}</div>
              <i
                v-if="cr.council == 'OUTRO'"
                class="pi pi-question-circle p-warning p-pt-0 p-ml-2"
                v-tooltip.right="'Logo confirmaremos seu registro'"
              />
            </div>
          </div>
          <div v-if="!editDisplay" class="p-text-right p-pt-0 buttons-council">
            <div>
              <Button
                class="p-button-edit p-ml-auto p-pt-0"
                label="excluir"
                @click="editation()"
              >
                <i class="pi pi-pencil"></i>
              </Button>
            </div>
            <div>
              <Button
                class="p-button-edit p-ml-auto p-pt-0"
                label="excluir"
                @click="remove(index)"
              >
                <img
                  class="garbage-icon"
                  src="../../../assets/images/delete.svg"
                />
              </Button>
            </div>
          </div>
          <div v-if="editDisplay" class="p-text-right p-pt-0 buttons-council">
            <div>
              <Button
                class="p-button-edit p-ml-auto p-pt-0"
                label="excluir"
                @click="editCouncil(cr)"
              >
                <i class="pi pi-check" style="color: gray;"></i>
              </Button>
            </div>
            <div>
              <Button
                class="p-button-edit p-ml-auto p-pt-0"
                label="excluir"
                @click="close()"
              >
                <i class="pi pi-times"></i>
              </Button>
            </div>
          </div>
        </div>
        <div class="speciality-container">
          <div class="p-grid mb-2 p-mt-1 p-jc-center ml-0">
            <InputText
              v-if="cr.council !== 'OUTRO'"
              v-model="cr.number"
              class="numberCr"
              placeholder="Número do registro"
              :style="registerStyleLeft(index)"
              :disabled="!editDisplay"
            />
            <InputText
              v-else
              v-model="cr.description"
              class="numberCr"
              placeholder="Descrição da profissão"
              :style="registerStyleLeft(index)"
              :disabled="!editDisplay"
            />
            <Dropdown
              v-model="cr.uf"
              class="selectUF2"
              :options="listStates"
              optionLabel="name"
              optionValue="code"
              placeholder="UF"
              :style="registerStyleRight(index)"
              :disabled="!editDisplay"
            />
          </div>
          <div
            class="speciality-block"
            style="margin-bottom: 20px"
            v-for="(speciality, s) in cr.specialityList"
            :key="s"
          >
            <div class="p-col p-speciality">
              <div class="p-grid">
                <div class="text-color">Especialidade</div>
              </div>
                <div class="p-text-right p-pt-0 buttons-council">
                  <div>
                    <Button
                      class="p-button-edit p-ml-auto p-pt-0"
                      label="excluir"
                      @click="editSpec(speciality)"
                    >
                      <i class="pi pi-pencil" style="color: gray;"></i>
                    </Button>
                  </div>
                  <div>
                    <Button
                      class="p-button-edit p-ml-auto p-pt-0"
                      label="excluir"
                      @click="removeSpeciality(index, s)"
                    >
                      <img
                        class="garbage-icon"
                        src="../../../assets/images/delete.svg"
                      />
                    </Button>
                  </div>
                </div>
            </div>
            <div class="p-grid p-mt-1 p-jc-center">
              <div>
                <InputText
                  placeholder="Especialidade"
                  v-model="speciality.description"
                  class="std-input speciality-input-l"
                  style="width: 205px;"
                  @blur="error['description'] = ''"
                ></InputText>
                <span class="p-input-icon-right">
                  <InputText
                    placeholder="Código"
                    v-model="speciality.rqeNumber"
                    class="std-input speciality-input-r"
                    @blur="error['rqeNumber'] = ''"
                  ></InputText>
                </span>
              </div>
                <small v-if="error['description']" class="col-12 p-invalid p-mt-2">
                  <i class="pi pi-times-circle p-ml-1" />
                  {{ error["description"][0] }}
                </small>
                <small v-if="error['rqeNumber']" class="col-12 p-invalid p-mt-2">
                  <i class="pi pi-times-circle p-ml-1" />
                  {{ error["rqeNumber"][0] }}
                </small>
              <div v-if="cr.council == 'OUTRO' || cr.council == 'CRN' && cr.specialityList[s].actuationList.length > 0">
                <div
                  v-for="(actuation, a) in cr.specialityList[s].actuationList"
                  class="p-grid p-mt-3"
                  :key="a"
                >
                  <InputText
                    placeholder="Área de atuação"
                    v-model="actuation.description"
                    class="std-input actuation-input-l"
                  ></InputText>
                  <span class="p-input-icon-right">
                    <i v-if="cr.specialityList[s].actuationList.length >= 1" class="pi pi-pencil" style="color: gray; right: 40px; cursor: pointer;" @click="editActuation(actuation)"></i>
                    <img
                      v-if="cr.specialityList[s].actuationList.length >= 1"
                      class="garbage-icon_inside"
                      src="../../../assets/images/delete.svg"
                      @click="removeActuation(index, s, a)"
                    />
                    <InputText
                      placeholder="Código"
                      v-model="actuation.rqeNumber"
                      class="std-input actuation-input-r"
                      style="width: 207px"
                    ></InputText>
                  </span>
                </div>
              </div>
              <div v-if="cr.specialityList[s].actuationList.length == 0 && cr.council == 'CRN'" class="p-grid p-jc-center p-mt-4">
                <Button
                  class="std-button-o-b"
                  label="nova área de atuação"
                  :disabled="!cr.number || !cr.uf"
                  @click="newActuation(index, s)"
                  v-tooltip.bottom="'Nova area de atuação'"
                />
              </div>
              <div v-if="cr.specialityList[s].actuationList.length == 0 && cr.council == 'OUTRO'" class="p-grid p-jc-center p-mt-4">
                <Button
                    class="std-button-o-b"
                    label="nova área de atuação"
                    :disabled="!cr.description || !cr.uf"
                    @click="newActuation(index, s)"
                    v-tooltip.bottom="'Nova area de atuação'"
                />
              </div>
              <div v-if="cr.specialityList[s].actuationList.length > 0 && cr.council == 'CRN'" class="p-grid p-jc-center p-mt-4">
                <Button
                  class="std-button-o-b"
                  label="incluir área de atuação"
                  :disabled="!cr.number || !cr.uf"
                  @click="includeActuation(index, s)"
                  v-tooltip.bottom="'incluir área de atuação'"
                />
              </div>
              <div v-if="cr.specialityList[s].actuationList.length > 0 && cr.council == 'OUTRO'" class="p-grid p-jc-center p-mt-4">
                <Button
                  class="std-button-o-b"
                  label="incluir área de atuação"
                  :disabled="!cr.description || !cr.uf"
                  @click="includeActuation(index, s)"
                  v-tooltip.bottom="'incluir área de atuação'"
                />
              </div>
            </div>
          </div>
          <div v-if="cr.council !== 'OUTRO' && cr.specialityList.length == 0" class="p-grid p-jc-center p-mt-4 my-0 mx-auto">
            <Button
              class="std-button-o-b"
              label="Nova especialidade"
              :disabled="
                !cr.number || !cr.uf || specialityFilled(index)
              "
              @click="newSpeciality(index, cr.council)"
              v-tooltip.bottom="'Nova especialidade'"
            />
          </div><div v-if="cr.council == 'OUTRO' && cr.specialityList.length == 0" class="p-grid p-jc-center p-mt-4 my-0 mx-auto">
            <Button
              class="std-button-o-b"
              label="Nova especialidade"
              :disabled="!cr.description || !cr.uf || specialityFilled(index)"
              @click="newSpeciality(index, cr.council)"
              v-tooltip.bottom="'Nova especialidade'"
            />
          </div>
          <div v-if="cr.specialityList.length > 0" class="p-grid p-jc-center p-mt-4 my-0 mx-auto">
            <Button
              class="std-button-o-b"
              label="incluir especialidade"
              :disabled="specialityFilled(index)"
              @click="includeSpeciality(index, cr.council)"
              v-tooltip.bottom="'incluir especialidade'"
            />
          </div>
        </div>
      </div>
    </div>  -->
  </div>
  <div v-if="cfmOnline">
    <div v-for="(cr, index) in cfm" class="customprofile-wrapper" :key="cr.council">
      <div class="customprofile-panel cfm-card">
        <div
          class="p-grid p-ml-2 justify-content-between"
          style="
             {
              height: '30px';
            }
          "
        >
          <div class="p-grid mb-2 p-mt-1 p-jc-center ai-center">
            CRM
            <span style="font-weight: 700; margin: 0 5px"
              >{{ cr.number }}/{{ cr.uf }}</span
            >
            <!-- <span>{{ cr.uf }}</span> -->
            <span>
              <i
                v-if="cr.council == 'CRM'"
                class="pi pi-check-circle p-warning p-pt-0"
                style="color: limegreen"
                v-tooltip.right="'Registro verificado'"
              />
            </span>
          </div>
          <div class="p-col-2 p-text-right p-pt-0">
            <Button
              class="p-button-icon p-button-text p-ml-auto p-pt-0"
              label="excluir"
              @click="removeCfm(index)"
            >
              <img class="garbage-icon" src="../../../assets/images/delete.svg" />
            </Button>
          </div>
        </div>
        <!-- <hr class="line-rqe"> -->
        <div class="speciality-container">
          <div
            style="margin-bottom: 0px"
            v-for="(speciality, s) in cr.specialityList"
            :key="s"
          >
            <div class="p-mt-1 p-jc-center flex-column">
              <div class="p-mt-1 flex title-council">
                <span class="title-council"
                  ><h6>{{ speciality.description }}</h6></span
                >
                <span
                  ><p>RQE {{ speciality.rqeNumber }}</p></span
                >
              </div>
              <!-- <hr class="line-speciality" style="margin: 5px 0 10px;"> -->
              <div class="p-mt-1 p-jc-center">
                <ul style="margin-top: 0">
                  <li
                    v-for="(actuation, a) in cr.specialityList[s].actuationList"
                    class="list-rqe"
                    :key="a"
                  >
                    <span
                      >{{ actuation.description }}
                      <strong>RQE {{ actuation.rqeNumber }}</strong></span
                    >
                    <!-- <hr class="line-actuation"> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-d-flex p-jc-center">
    <Button
      class="std-button mb-4"
      type="button"
      label="Continuar"
      :disabled="!isFilled"
      style="width: 317px; height: 56px"
      @click="continueRegistry()"
    />
  </div>

  <Dialog
    v-model:visible="confirmRemoveDialog"
    header="Excluir registro"
    style="width: 284px; height: 205px"
  >
    Tem certeza que deseja excluir esse registro?
    <template #footer>
      <Button
        class="std-button-o"
        label="Cancelar"
        @click="confirmRemoveDialog = false"
        style="width: 8rem"
      ></Button>
      <Button
        class="std-button"
        label="Excluir"
        style="width: 8rem"
        @click="confirm"
      ></Button>
    </template>
  </Dialog>
  <Dialog
    header="Nomes incompatíveis"
    v-model:visible="displayRegistered"
    :style="{ width: '446px', borderRadius: '15px', }"
    class="incompatible-name"
    :modal="true"
  >
    <div class="confirmation-content">
      <div class="text-center text-base mb-5">
        <p>
          Encontramos diferenças entre o nome inserido na Ommed <br />
          e o nome cadastrado no Conselho Regional de Medicina.
        </p>
      </div>
      <div class="text-center text-sm mt-4 mb-3">
        <p class="fs-4">Selecione a opção correta.</p>
      </div>
      <div class="radio-buttons" style="margin-left: 140px">
        <div style="margin-bottom: 5px">
          <RadioButton name="select" :value="nameOmmed" v-model="select" />
          <label for="nameOmmed" class="color-gray__3 mr-5 ml-2"
            >{{ nameOmmed }}</label
          >
        </div>
        <div>
          <RadioButton name="select" :value="nameCfm" v-model="select" />
          <label for="nameCfm" class="color-gray__3 mr-5 ml-2">{{ nameCfm }}</label>
        </div>
      </div>
      <!-- <div class='drop-zone'>
        <div v-for='(item, index) in nameOmmed' :key='index' class='drag-el' :id="item" draggable="true" @dragstart="startDrag($event)">
          {{ item }}
        </div>
      </div>
      <div class='drop-zone'>
        <div v-for='(item, index) in nameCfm' :key='index' class='drag-el' :id="item" draggable="true" @dragstart="startDrag($event)">
          {{ item }}
        </div>
      </div>
      <div class="text-center text-4xl my-5">
        <InputText
          class="std-middle upper"
          type="text"
          @keypress="OnlyLetters($event)"
          v-model="firstName"
          placeholder="Nome"
          maxlength="128"
          @dragover="onDragOver($event)"
          @drop="onDropFirst($event)"
        />
        <InputText
          class="std-middle upper"
          type="text"
          @keypress="OnlyLetters($event)"
          v-model="lastName"
          placeholder="Sobrenome"
          maxlength="128"
          @dragover="onDragOver($event)"
          @drop="onDropLast($event)"
        />
      </div> -->
    </div>
    <div class="d-flex jc-center mt-5 mb-5">
      <Button class="btn-std" label="Continuar e incluir registro" @click="updateName" />
    </div>
  </Dialog>
  <Dialog header="Incluir especialidade" v-model:visible="includeSpec" :style="{ width: '446px', borderRadius: '15px' }" :modal="true" class="speciality-modal" >
    <div class="confirmation-content">
      <div class="text-center text-4xl mb-5">
        <div>
          <InputText
            placeholder="Especialidade"
            v-model="speciality.description"
            class="std-input speciality-input-l"
            style="width: 205px;"
            @blur="errorResponse['description'] = ''"
          ></InputText>
          <span class="p-input-icon-right">
            <InputText
              placeholder="Nº do registro"
              v-model="speciality.rqeNumber"
              class="std-input speciality-input-r"
              @blur="errorResponse['rqeNumber'] = ''"
            ></InputText>
          </span>
        </div>
        <div v-if="errorSpeciality">
          <small class="p-invalid p-mt-2">
            <i class="pi pi-times-circle p-ml-1" />{{ errorSpeciality }}
          </small>
        </div>
        <small v-if="errorResponse['description']" class="p-invalid p-mt-2">
          <i class="pi pi-times-circle p-ml-1" />{{ errorResponse['description'][0] }}
        </small>
        <small v-if="errorResponse['rqeNumber']" class="p-invalid p-mt-2">
          <i class="pi pi-times-circle p-ml-1" />{{ errorResponse['rqeNumber'][0] }}
        </small>
      </div>
    </div>
    <div class="d-flex jc-center my-2">
      <Button
        class="btn-std btn-rqe gradient-btn"
        label="Incluir"
        @click="includeSpeciality(index, speciality)"
        @blur="errorSpeciality = ''"
      />
    </div>
  </Dialog>
</template>

<script>
import { ref, reactive, computed, watchEffect, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { states } from "../../../enums/States";
import { cr } from "../../../enums/CR";
import router from "../../../router";
// import { Cfm } from "../../../enums/Cfm";
import { RegistryParams } from "../../core/grpc/generated/cfmRepository_pb";
import cfmService from "../../core/services/cfmService";
import { SendEmailAfterUserCloseBrowserRequest } from "../../core/grpc/generated/professionalAccount_pb";
import professionalAccountService from "../../core/services/professionalAccountService";
import {
  AddCouncilRequest,
  EditCouncilRequest,
  AddSpecialtyRequest,
  EditSpecialtyRequest,
  DeleteSpecialtyRequest,
  AddActionActuationRequest,
  EditActionActuationRequest,
  DeleteActionActuationRequest,
  DeleteCouncilRequest,
  UpdateNameRequest,
} from "../../core/grpc/generated/professionalProfile_pb";
import professionalProfileService from "../../core/services/professionalProfileService";

export default {
  name: "Records",
  setup() {
    const count = ref(4);
    let typeRegistry = ref("");
    let firstName = ref("");
    let lastName = ref("");
    let nameOmmed = ref();
    let nameCfm = ref();
    let select = ref("");
    let uf = ref("");
    let listCr = cr;
    let cfmList = [];
    let listStates = ref(states);
    let numberRegistry = ref("");
    let councilId = ref("");
    let number = ref("");
    let crs = reactive([]);
    let cfm = reactive([]);
    let cfmOnline = ref(false);
    let cfmOffline = ref(false);
    let description = ref("");
    let confirmRemoveDialog = false;
    let confirmRemove = ref(false);
    let currentRemove = ref(null);
    let displayRegistered = ref(false);
    let includeSpec = ref(false);
    let editDisplay = ref(false);
    let professional = ref();
    let error = ref([]);
    let errorResponse = ref([]);
    let errorMessage = ref();
    let errorSpeciality = ref();
    let speciality = ref({
      description: "",
      rqeNumber: "",
    });

    const toast = useToast();
    const isFilled = computed(() => crs.length > 0 || cfm.length > 0);

    onMounted(async () => {
      const infos = await professionalProfileService.getInfo();
      if (infos.success == true) {
        let res = JSON.parse(infos.data);
        professional.value = {
          firstName: res.firstName,
          lastName: res.lastName,
        };
      }
      await allCouncils();
      const councils = await professionalProfileService.listCouncil();
      if (councils.success == true) {
        let council = councils.councilprofessionalsList;
        console.log(council);
        if (council) {
          Object.keys(council).forEach(async (item) => {
            if (council[item].council != "CRM") {
              cfmOffline.value = true;
              const value = listCr.find(({ code }) => code == council[item].council);
              crs.push({
                councilId: council[item].councilid,
                council: council[item].council,
                number: council[item].number,
                description: council[item].description,
                uf: council[item].state,
                validation: council[item].validation,
                specialityList: council[item].specialityList,
                name: value.name,
              });
              // await addData();
            }
          });
        }
        // cfmList.push(res);
      }
    });

    const clear = () => {
      description.value = "";
      number.value = "";
      uf.value = "";
      errorMessage.value = "";
    };
    const clearError = () => {
      errorMessage.value = '';
    }

    const startDrag = (evt) => {
      evt.dataTransfer.setData("text/plain", evt.target.id);
    };

    const onDragOver = (event) => {
      event.preventDefault();
    };

    const onDropFirst = (event) => {
      event.preventDefault();
      const id = event.dataTransfer.getData("text");
      firstName.value = document.getElementById(id).innerText;
      const dropzone = event.target;
      console.log("Dropzone", dropzone);
      dropzone.value = firstName.value;
      // event.dataTransfer.clearData();
    };

    const onDropLast = (event) => {
      event.preventDefault();
      const id = event.dataTransfer.getData("text");
      lastName.value = document.getElementById(id).innerText;
      const dropzone = event.target;
      console.log("Dropzone", dropzone);
      dropzone.value = lastName.value;
      // event.dataTransfer.clearData();
    };

    const allCouncils = async () => {
      const councils = await professionalProfileService.listCouncil();
      if (councils.success == true) {
        let council = councils.councilprofessionalsList;
        console.log(council);
        if (council) {
          Object.keys(council).forEach(async (item) => {
            if (council[item].council == "CRM") {
              cfmOnline.value = true;
              cfm.push({
                councilId: council[item].councilid,
                council: council[item].council,
                number: council[item].number,
                uf: council[item].state,
                validation: council[item].validation,
                specialityList: council[item].specialityList,
              });
            }
            // else {
            //   cfmOffline.value = true;
            //   const value = listCr.find(({ code }) => code == council[item].council);
            //   crs.push({
            //     councilId: council[item].councilid,
            //     council: council[item].council,
            //     number: council[item].number,
            //     description: council[item].description,
            //     uf: council[item].state,
            //     validation: council[item].validation,
            //     specialityList: council[item].specialityList,
            //     name: value.name,
            //   });
            // }
          });
        }
      }
    };

    const newSpeciality = (index, council) => {
      // if (council == "OUTRO" || council == "CRN") {
      crs[index].specialityList.push({
        specialtyid: "",
        description: "",
        rqeNumber: "",
        actuationList: reactive([]),
      });
      // } else {
      //   crs[index].specialityList.push({ specialtyid: "", description: "", rqeNumber: "" });
      // }
    };

    const includeDialog = (value) => {
      councilId.value = value;
      errorSpeciality.value = '';
      errorResponse.value['description'] = '';
      errorResponse.value['rqeNumber'] = '';
      includeSpec.value = true;
      console.log(councilId.value);
    };

    const includeSpeciality = async () => {
      let index = councilId.value;
      newSpeciality(index);
      let last = "";
      last = crs[index].specialityList.length - 1;
      console.log("LAST", last);
      const add = await addSpeciality(index, last);
      console.log(add);
      if(add.success == true) {
          console.log("caiu aqui")
          councilId.value = '';
          speciality.value.description = '';
          speciality.value.rqeNumber = '';
          includeSpec.value = false;
      } else if(add.success == false && add.errormessage == "Registro já cadastrado!"){
        speciality.value.description = '';
        speciality.value.rqeNumber = '';
        // includeSpec.value = false;
        // if (council == "OUTRO" || council == "CRN") {
        // console.log("caiu aqui")
        // crs[index].specialityList.push({
        //   specialtyid: "",
        //   description: "",
        //   rqeNumber: "",
        //   actuationList: reactive([]),
        // });
        // } else {
        //   crs[index].specialityList.push({ specialtyid: "", description: "", rqeNumber: "" });
        // }
      } else {
        crs[index].specialityList.splice(last, 1);
      }
      console.log("Add especialidade", add);
    };

    const newActuation = (index, spec) => {
      crs[index].specialityList[spec].actuationList.push({
        actionactuationid: "",
        description: "",
        rqeNumber: "",
        exclusive: "",
      });
    };

    const includeActuation = async (index, spec) => {
      console.log(crs[index].specialityList[spec]);
      // if(crs[index].specialityList[spec].actuationList.length == 0) {
      //   crs[index].specialityList[spec].actuationList.push({
      //     description: "",
      //     number: "",
      //     exclusive: ""
      //   });
      // } else {
      let id = crs[index].specialityList[spec].specialtyid;
      console.log("ID Especialidade", id);
      const add = await addActuation(index, spec, id);
      if (add.success == true) {
        let actuationId = JSON.parse(add.data);
        crs[index].specialityList[spec].actuationList.push({
          actionactuationid: "",
          description: "",
          rqeNumber: "",
          exclusive: "",
        });
      } else if (add.success == false && add.errormessage == "Registro já cadastrado!") {
        console.log("caiu aqui");
        crs[index].specialityList[spec].actuationList.push({
          actionactuationid: "",
          description: "",
          rqeNumber: "",
          exclusive: "",
        });
      }
      console.log("Add Actuation", add);
      console.log(crs[index].specialityList[spec].actuationList);
      // }
    };

    const addSpeciality = async (index, s) => {
      const request = new AddSpecialtyRequest();
      let council = "";
      if (crs[index]) {
        council = crs[index];
      }
      // let description = '';
      // if(council.specialityList[s].description) {
      //   description = council.specialityList[s].description;
      // }
      // council.specialityList[s].description = speciality.value.description;
      // council.specialityList[s].rqeNumber = speciality.value.rqeNumber;
      
      console.log(council);
      request.setCouncilid(council.councilId);
      // request.setDescription(council.specialityList[s].description);
      // request.setRqeNumber(council.specialityList[s].rqeNumber.toString());
      request.setDescription(speciality.value.description);
      request.setRqeNumber(speciality.value.rqeNumber.toString());
      const response = await professionalProfileService.addSpeciality(request);
      if (response.success == true) {
        const id = JSON.parse(response.data).id;
        council.specialityList[s].specialtyid = id;
        council.specialityList[s].description = speciality.value.description;
        council.specialityList[s].rqeNumber = speciality.value.rqeNumber;
      } else if(response.success == false && response.errormessage == "Registro já cadastrado!") {
        errorSpeciality.value = response.errormessage;
        crs[index].specialityList.splice(s, 1);
      } else {
        errorResponse.value = JSON.parse(response.data);
      }
      return response;
    };

    const editSpec = async (data) => {
      console.log(data);
      const request = new EditSpecialtyRequest();
      // if(!data.description || !data.rqeNumber) {
      //   toast.add({ severity: "warning", summary: "Atenção", detail: "Descrição e número são obrigatórios", life: 3000, });
      // } else {
      request.setSpecialtyid(data.specialtyid);
      request.setDescription(data.description);
      request.setRqeNumber(data.rqeNumber);
      console.log(request);
      const response = await professionalProfileService.editSpeciality(request);
      if (response.success == false) {
        error.value = JSON.parse(response.data);
        console.log(error.value);
      }
      // }
    };

    const addActuation = async (index, s, specialtyId) => {
      let speciality = "";
      let last = "";
      if (crs[index]) {
        speciality = crs[index].specialityList[s];
        if (speciality.actuationList.length != 0) {
          last = speciality.actuationList.length - 1;
        }
      }
      let description = "";
      if (speciality.actuationList[last].description) {
        description = speciality.actuationList[last].description;
      }
      const request = new AddActionActuationRequest();
      request.setSpecialtyid(specialtyId);
      request.setDescription(description);
      request.setRqeNumber(speciality.actuationList[last].rqeNumber.toString());
      // request.setExclusive(speciality.actuationList[last].exclusive.toString() || "");
      const response = await professionalProfileService.addActionActuation(request);
      console.log(response);
      if (response.success == true) {
        const id = JSON.parse(response.data).id;
        speciality.actuationList[last].actionactuationid = id;
      }
      return response;
    };

    const editActuation = async (data) => {
      console.log(data);
      // if(!data.description || !data.rqeNumber) {
      //   toast.add({ severity: "warning", summary: "Atenção", detail: "Descrição e número são obrigatórios", life: 3000, });
      // } else {
      const request = new EditActionActuationRequest();
      request.setActionactuationid(data.actionactuationid);
      request.setDescription(data.description);
      request.setRqeNumber(data.rqeNumber);
      console.log(request);
      const response = await professionalProfileService.editActuation(request);
      if (response.success == false) {
        error.value = JSON.parse(response.data);
        console.log(error.value);
      }
      // }
    };

    const addSpecialityCrm = async (index, s) => {
      const request = new AddSpecialtyRequest();
      let council = "";
      if (cfm[index]) {
        council = cfm[index];
      }
      console.log(council);
      request.setCouncilid(council.councilId);
      request.setDescription(council.specialityList[s].description);
      request.setRqeNumber(council.specialityList[s].rqeNumber.toString());
      const response = await professionalProfileService.addSpeciality(request);
      if (response.success == true) {
        const id = JSON.parse(response.data).id;
        council.specialityList[s].specialtyid = id;
      }
      return response;
    };

    const addActuationCrm = async (index, s, specialtyId) => {
      let speciality = "";
      let last = "";
      if (cfm[index]) {
        console.log("CFM", cfm[index]);
        speciality = cfm[index].specialityList[s];
        console.log("Especialidade", speciality);
        if (speciality.actuationList.length != 0) {
          last = speciality.actuationList.length - 1;
        }
      }
      const request = new AddActionActuationRequest();
      request.setSpecialtyid(specialtyId);
      request.setDescription(speciality.actuationList[last].description);
      request.setRqeNumber(speciality.actuationList[last].rqeNumber.toString());
      request.setExclusive(speciality.actuationList[last].exclusive.toString());
      const response = await professionalProfileService.addActionActuation(request);
      if (response.success == true) {
        const id = JSON.parse(response.data).id;
        speciality.actuationList[last].actionactuationid = id;
      }
      return response;
    };

    const checkCfm = async () => {
      let request = new RegistryParams();
      let name = professional.value.firstName;
      console.log(name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      console.log(uf.value);
      console.log(parseInt(number.value));
      request.setRegistry(parseInt(number.value));
      request.setUf(uf.value);
      request.setName(name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      const response = await cfmService.consultRegistryName(request);
      if (response.success) {
        // console.log(response);
        cfmList.push(response);
      } else {
        error.value = JSON.parse(response.errormessage);
        Object.keys(error.value).forEach((item) => {
          console.log(item + " = " + error.value[item]);
        });
      }
    };

    const addCouncil = async (data) => {
      const item = listCr.find(({ code }) => code == typeRegistry.value);
      let request = new AddCouncilRequest();
      console.log(data);
      request.setCouncil(data.type);
      request.setNumber(data.number.toString());
      request.setDescription(data.description);
      request.setState(data.uf);
      const response = await professionalProfileService.addCouncil(request);
      if (response.success == true) {
        if (typeRegistry.value != "OUTRO") {
          if (typeRegistry.value == "CRM") {
            // cfm.push({
            // councilId: JSON.parse(response.data).id,
            // council: item.code,
            // number: data.number.toString(),
            // uf: data.uf,
            // validation: data.validation || '1',
            // specialityList: data.speciality,
            // name: item.name,
            // });
            // await addDataCrm();
            // console.log(cfm);
            cfm.length = 0;
            await allCouncils();
          } else {
            crs.push({
              councilId: JSON.parse(response.data).id,
              council: item.code,
              number: number.value,
              uf: uf.value,
              validation: "1",
              specialityList: reactive([]),
              name: item.name,
            });
            // await addData();
          }
          typeRegistry.value = "";
          number.value = "";
          uf.value = "";
        } else if (typeRegistry.value == "OUTRO") {
          crs.push({
            councilId: JSON.parse(response.data).id,
            council: "OUTRO",
            uf: uf.value,
            validation: "1",
            description: description.value,
            specialityList: reactive([]),
            name: item.name,
          });
          typeRegistry.value = "";
          description.value = "";
          uf.value = "";
        }
      } else if (response.success == false && response.data) {
        let res = JSON.parse(response.data);
        nameOmmed.value = res.nameOmmed;
        nameCfm.value = res.nameCfm;
        console.log("Nome Ommed", nameOmmed.value);
        console.log("Nome CFM", nameCfm.value);
        displayRegistered.value = true;
      } else {
        console.log(response.data);
        if(response.data != '') {
          error.value = JSON.parse(response.data);
          console.log(error.value);
          Object.keys(error.value).forEach((item) => {
            console.log(item + " = " + error.value[item]);
          });
        } else {
          count.value = 4;
          errorMessage.value = response.errormessage;
        }
      }
    };

    const editation = () => {
      editDisplay.value = true;
    };

    const editCouncil = async (data) => {
      let request = new EditCouncilRequest();
      console.log(data);
      request.setCouncilid(data.councilId);
      request.setCouncil(data.council);
      request.setNumber(data.number.toString());
      request.setDescription(data.description);
      request.setState(data.uf);
      const response = await professionalProfileService.editCouncil(request);
      if (response.success == false && response.data) {
        let error = JSON.parse(response.data);
        Object.keys(error).forEach((item) => {
          console.log(item + " = " + error[item]);
        });
      } else if (response.success == true) {
        toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: "Registro editado com sucesso",
          life: 3000,
        });
        editDisplay.value = false;
      }
    };

    const includeCouncil = async () => {
      console.log(typeRegistry.value);
      if (typeRegistry.value == "CRM") {
        // await checkCfm();
        // const hasCfm = cfmList.find(({ council }) => {
        // if(council.registry == parseInt(number.value) && council.uf == uf.value) {
        //   return true;
        // }
        // });
        // if(hasCfm) {
        cfmOnline.value = true;
        const council = {
          type: typeRegistry.value,
          number: number.value,
          description: "",
          uf: uf.value,
          // speciality: hasCfm.council.specialityList
        };
        addCouncil(council);
        // }
      } else if (typeRegistry.value != "CRM") {
        cfmOffline.value = true;
        let otherCouncil = {
          type: typeRegistry.value,
          number: number.value,
          description: description.value,
          uf: uf.value,
        };
        addCouncil(otherCouncil);
      }
    };

    const addDataCrm = async () => {
      if (cfm.length) {
        console.log("entrou aqui");
        await Promise.all(
          cfm.map(async (council, index) => {
            if (council.specialityList.length !== 0) {
              const output = council.specialityList.filter(Boolean);
              if (output != []) {
                output.map(async (spec, s) => {
                  // console.log("Speciality:", spec);
                  console.log("S", s);
                  const resultSpeciality = await addSpecialityCrm(index, s);
                  if (resultSpeciality.success == true) {
                    let speciality = JSON.parse(resultSpeciality.data);
                    if (spec.actuationList.length != 0) {
                      console.log("index", index);
                      console.log("s", s);
                      console.log("speciality Id", speciality.id);
                      const resultActuation = await addActuationCrm(
                        index,
                        s,
                        speciality.id
                      );
                      if (resultActuation.success == false) {
                        if (resultSpeciality.data) {
                          error.value = JSON.parse(resultSpeciality.data);
                          Object.keys(error).forEach((item) => {
                            console.log(item + " = " + error[item]);
                            toast.add({
                              severity: "error",
                              summary: item,
                              detail: error[item][0],
                              life: 3000,
                            });
                          });
                        } else if (
                          resultSpeciality.errormessage === "Registro já cadastrado!"
                        ) {
                          return {
                            success: true,
                            error: resultSpeciality.errormessage,
                          };
                        }
                      }
                    } else {
                      return {
                        success: true,
                      };
                    }
                  } else if (
                    resultSpeciality.errormessage === "Registro já cadastrado!"
                  ) {
                    return {
                      success: true,
                      error: resultSpeciality.errormessage,
                    };
                  } else {
                    let error = JSON.parse(resultSpeciality.data);
                    console.log(error);
                    Object.keys(error).forEach((item) => {
                      console.log(item + " = " + error[item]);
                      toast.add({
                        severity: "error",
                        summary: item,
                        detail: error[item][0],
                        life: 3000,
                      });
                    });
                  }
                });
              }
            } else {
              return {
                success: true,
              };
            }
          })
        );
      }
    };

    const addData = async () => {
      await Promise.all(
        crs.map(async (council, index) => {
          if (council.specialityList.length !== 0) {
            const output = council.specialityList.filter(Boolean);
            console.log("Aqui", output);
            if (output != []) {
              output.map(async (spec, s) => {
                // console.log("Speciality:", spec);
                console.log("S", s);
                const resultSpeciality = await addSpeciality(index, s);
                if (resultSpeciality.success == true) {
                  // let speciality = JSON.parse(resultSpeciality.data);
                  // if (spec.actuationList.length != 0) {
                  //   const resultActuation = await addActuation(
                  //     index,
                  //     s,
                  //     speciality.id
                  //   );
                  //   if(resultActuation.success == false) {
                  //     let error = JSON.parse(resultSpeciality.data);
                  //     Object.keys(error).forEach((item) => {
                  //     console.log(item + " = " + error[item]);
                  //       toast.add({
                  //         severity: "error",
                  //         summary: item,
                  //         detail: error[item][0],
                  //         life: 3000,
                  //       });
                  //     });
                  //   } else if(resultSpeciality.errormessage === "Registro já cadastrado!") {
                  //     return {
                  //       success: true,
                  //       error: resultSpeciality.errormessage
                  //     };
                  //   }
                  // }
                } else if (resultSpeciality.errormessage === "Registro já cadastrado!") {
                  return {
                    success: true,
                    error: resultSpeciality.errormessage,
                  };
                } else {
                  let error = JSON.parse(resultSpeciality.data);
                  console.log(error);
                  Object.keys(error).forEach((item) => {
                    console.log(item + " = " + error[item]);
                    toast.add({
                      severity: "error",
                      summary: item,
                      detail: error[item][0],
                      life: 3000,
                    });
                  });
                  return {
                    success: false,
                  };
                }
              });
            }
          } else {
            return {
              success: true,
            };
          }
        })
      );
    };

    const continueRegistry = async () => {
      // await addData();
      // console.log(res);
      setTimeout(() => {
        router.push("/account/upload");
      }, 1000 * 4);
    };

    const remove = async (item) => {
      let request = new DeleteCouncilRequest();
      console.log("Conselho", crs[item]);
      request.setCouncilid(crs[item].councilId);
      const response = await professionalProfileService.deleteCouncil(request);
      if (response.success) {
        crs.splice(item, 1);
      }
    };

    const removeCfm = async (item) => {
      let request = new DeleteCouncilRequest();
      console.log("CRM", cfm[item]);
      request.setCouncilid(cfm[item].councilId);
      const response = await professionalProfileService.deleteCouncil(request);
      if (response.success) {
        cfm.splice(item, 1);
      }
    };

    const removeSpeciality = async (index, item) => {
      if (
        !crs[index].specialityList[item].description &&
        !crs[index].specialityList[item].rqeNumber
      ) {
        crs[index].specialityList.splice(item, 1);
      } else {
        let request = new DeleteSpecialtyRequest();
        request.setSpecialtyid(crs[index].specialityList[item].specialtyid);
        const response = await professionalProfileService.deleteSpeciality(request);
        if (response.success == true) {
          crs[index].specialityList.splice(item, 1);
        }
      }
    };

    const removeActuation = async (index, spec, item) => {
      if (
        !crs[index].specialityList[spec].actuationList[item].description &&
        !crs[index].specialityList[spec].actuationList[item].rqeNumber
      ) {
        crs[index].specialityList[spec].actuationList.splice(item, 1);
      } else {
        let request = new DeleteActionActuationRequest();
        console.log(crs[index].specialityList[spec].actuationList[item]);
        request.setActionactuationid(
          crs[index].specialityList[spec].actuationList[item].actionactuationid
        );
        console.log(request);
        const response = await professionalProfileService.deleteActionActuation(request);
        if (response.success == true) {
          crs[index].specialityList[spec].actuationList.splice(item, 1);
        }
      }
    };

    const redirectRecords = () => {
      displayRegistered.value = false;
      router.push("/account/upload");
    };

    const confirm = () => {
      confirmRemove.value = true;
      remove(currentRemove);
      confirmRemoveDialog = false;
    };

    const specialityFilled = (index) => {
      if (crs[index].specialityList) {
        let last = crs[index].specialityList.length - 1;
        if (crs[index].specialityList[last])
          return (
            !crs[index].specialityList[last].description ||
            !crs[index].specialityList[last].rqeNumber
          );
      } else {
        return false;
      }
    };

    const actuationFilled = (index, spec) => {
      if (crs[index].specialityList[spec].actuationList) {
        let last = crs[index].specialityList[spec].actuationList.length - 1;
        if (crs[index].specialityList[spec].actuationList[last])
          return (
            !crs[index].specialityList[spec].actuationList[last].description ||
            !crs[index].specialityList[spec].actuationList[last].number
          );
      } else {
        return false;
      }
    };

    const close = () => {
      editDisplay.value = false;
    };

    // const clear = () => {
    //   if (typeRegistry.value === "") {
    //     crs = crs.filter((v) => {
    //       v.description === "";
    //     });
    //   } else
    //     crs = crs.filter((v) => {
    //       v.description !== "53";
    //     });
    // };

    const updateName = async () => {
      let name = select.value.split(" ");
      firstName.value = name.shift();
      lastName.value = name.join(" ");
      const request = new UpdateNameRequest();
      request.setFirstname(firstName.value);
      request.setLastname(lastName.value);
      console.log(request);
      const response = await professionalProfileService.updateName(request);
      if (response.success == true) {
        await includeCouncil();
        displayRegistered.value = false;
      }
    };

    const registerStyleLeft = (index) => {
      if (crs[index].specialityList.length > 0) {
        return "border-top-left-radius: 8px !important;";
      } else {
        return "border-top-left-radius: 8px !important; border-bottom-left-radius: 8px !important;";
      }
    };

    const registerStyleRight = (index) => {
      if (crs[index].specialityList.length > 0) {
        return "border-radius: 0 8px 8px 0px !important;";
      } else {
        return "border-top-right-radius: 8px !important; border-bottom-right-radius: 8px !important;";
      }
    };

    watchEffect(() => {
      window.addEventListener("unload", async (e) => {
        e.preventDefault();
        e.returnValue = "";
        await sendEmailOnClose();
      });
      setInterval(() => {
        if (count.value > 0) {
          count.value--;
        }
        else if(count.value == 0 && errorMessage.value) {
          clearError();
          count.value = 4;
        }
      }, 1000);
      // console.log(cfmList[0]);
    });

    const sendEmailOnClose = async () => {
      const request = new SendEmailAfterUserCloseBrowserRequest();
      const email = localStorage.getItem("email");
      request.setEmail(email);
      await professionalAccountService.sendEmailOnClose(request);
    };

    const OnlyLetters = (e) => {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    };

    return {
      startDrag,
      onDragOver,
      onDropFirst,
      onDropLast,
      typeRegistry,
      firstName,
      lastName,
      nameOmmed,
      nameCfm,
      select,
      uf,
      crs,
      cfm,
      count,
      clear,
      clearError,
      close,
      error,
      errorMessage,
      errorResponse,
      errorSpeciality,
      listCr,
      listStates,
      cfmOnline,
      cfmOffline,
      description,
      isFilled,
      number,
      numberRegistry,
      newSpeciality,
      newActuation,
      confirm,
      updateName,
      includeDialog,
      sendEmailOnClose,
      confirmRemoveDialog,
      remove,
      removeCfm,
      removeActuation,
      removeSpeciality,
      specialityFilled,
      actuationFilled,
      includeSpeciality,
      includeSpec,
      speciality,
      editSpec,
      includeActuation,
      registerStyleLeft,
      registerStyleRight,
      includeCouncil,
      addActuation,
      editActuation,
      continueRegistry,
      redirectRecords,
      displayRegistered,
      editCouncil,
      editDisplay,
      editation,
      OnlyLetters,
      isNames: computed(() => firstName.value != "" && lastName.value != ""),
      // actuationStyleLeft, actuationStyleRight,
      // specialityStyleLeft, specialityStyleRight,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../app/css/Responsive.scss";

.logo {
  @include responsive(680px, major) {
    padding: 20px 0 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}

.incompatible-name {
  display: flex;
  flex-direction: column;
}

.speciality-block {
  margin-bottom: 20px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 1vw;
  // margin-left: -10px;
  // margin-right: -10px;
}

.p-dropdown:disabled {
  background: #CECECE !important;
}

.flex-row {
  justify-content: space-between;
}

.form {
  padding: 0px !important;
}

.pi {
  font-size: 1rem;
}

.p-input-icon-right {
  position: relative;
  display: inline-block;
}

.p-invalid {
  font-size: 1rem;
  display: flex;
  margin-top: 8px;
}

.p-ml-2 {
  margin-left: 0.5rem !important;
  margin-top: 2px;
}

.p-speciality {
  display: flex;
  justify-content: space-between;
}

.text-color {
  margin-left: 10px !important;
}

.pi-question-circle {
  @include responsive(680px, major) {
    display: none;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    .p-radiobutton-icon {
      background-color: #fff;
    }
  }
}

.drop-zone {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 15px;

  .drag-el {
    border: 1px solid #fa4f11;
    padding: 5px;
    border-radius: 10px;
    font-weight: 800;
    color: #fa4f11;
  }
}

.buttons-council {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.p-pt-0 {
  padding-top: 0 !important;
}

.p-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: 0.1rem;
}

.title-council {
  margin-right: 8px;

  // h6 {
  //   font-weight: 800;
  //   line-height: 1.5;
  //   margin: 0;
  // }
  span {
    h6 {
      font-weight: 500;
      margin: 0;
      line-height: 1.5;
      color: slategray;
    }

    p {
      margin: 0;
      line-height: 1.5;
      letter-spacing: 0;
      letter-spacing: 0;
      font-weight: 800;
      color: slategray;
    }
  }
}

hr {
  border-top: solid #dee2e6;
  border-width: 1px 0 0 0;
  margin: 1rem 0 0;
}

.line-rqe {
  margin: -10px 0 20px;
}

.line-speciality {
  margin: 5px 0 10px;
  width: 425px;
}

.line-actuation {
  margin-left: -40px;
  width: 425px;
}

.list-rqe {
  color: slategray;
}

.p-ml-3 {
  margin-left: 1rem !important;
}

.p-mt-0 {
  margin-top: 0 !important;
}

.p-mt-4 {
  margin-top: 1.3rem !important;
}

.p-col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.p-pt-0 {
  padding-top: 0 !important;
}

.p-text-right {
  text-align: right !important;
}

.p-col-2 {
  width: 16.6667%;
  flex: 0 0 auto;
  padding: 0.5rem;
}

.garbage-icon {
  margin: 0 auto;
  width: 12px;
  height: 12px;
}

.garbage-icon_inside {
  position: absolute;
  left: 155px;
  top: 21px;
  cursor: pointer;
}

.speciality {
  border-radius: 0 0 0 8px !important;
}

.speciality-span {
  border-radius: 0 0 8px 0 !important;
}

.p-jc-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.form {
  padding: 6px;
}
.p-button:disabled {
  background: #e0e0e0 !important;
  color: #828282 !important;
  border: none;
}

.p-button {
  height: 40px;
}

.p-button-icon:hover {
  border-color: transparent !important;
  border: none !important;
  color: transparent !important;
  background: transparent !important;
}

.p-button-edit {
  background-color: transparent;
  border: none;
  color: #fa4f11;
}

.p-button-edit:enabled:hover {
  background-color: transparent !important;
  color: #fa4f11;
  border: none;
}

.p-button-icon:active {
  color: transparent;
  border-color: transparent;
}

.p-button-icon:focus {
  box-shadow: none;
}

.std-input {
  height: 60px;
  background: #fff;
  align-items: center;
}

.std-button-o-b {
  width: 14.5rem;
  color: #fd6100;
  font-weight: bold;
  font-size: 14px;
  line-height: 190.94%;
  background: transparent;
  border-radius: 8px;
}

.account-form {
  min-height: 25vh;
}

.customprofile-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 25px auto;

  .customprofile-panel {
    @include responsive(690px, major) {
      width: 90vw;
    }
  }
}

.speciality-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 20px;
}

.cfm-card {
  height: auto;
  padding: 10px 26px !important;
}

.customprofile-panel {
  width: 478px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;

  .p-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: 0.1rem;
  }
  .p-jc-center {
    justify-content: center;
  }

  .p-mt-1 {
    margin-top: 0 !important;
  }

  .p-mt-3 {
    margin-top: 1rem !important;
  }
}

.p-dialog {
  .p-dialog-header {
    justify-content: space-between;
    .p-dialog-title {
      margin-left: 115px;
    }
  }

  .p-dialog-content {
    flex-direction: column;
  }
}

.numberCr {
  width: 319px !important;
  height: 60px;
  border-radius: 0px 0px 0px 8px;
  @include responsive(690px, major) {
    // width: 77vw !important;
    width: 50vw !important;
  }
  @include responsive(384px, major) {
    width: 47vw !important;
  }
}

.numberCr:hover {
  border-color: #ff6a33;
}

.selectCR {
  width: 415px !important;
  height: 60px !important;
  border-radius: 8px 8px 0px 0px;
  display: flex !important;
  border-bottom: 1px solid #ced4da;
  align-items: center;
  @include responsive(690px, major) {
    width: 75vw !important;
  }
  @include responsive(384px, major) {
    width: 75vw !important;
  }

  .p-inputtext {
    display: flex !important;
    justify-content: left !important;
    align-items: center !important;
    font-size: 14px !important;
  }
}

.selectUF {
  width: 96px !important;
  height: 60px !important;
  float: left;
  align-items: center;
  border-radius: 0px 0px 8px 0px;
  @include responsive(690px, major) {
    width: 25vw !important;
  }
  @include responsive(384px, major) {
    width: 25vw !important;
  }
}

.selectUF2 {
  width: 95px !important;
  height: 60px !important;
  float: left;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
}

.std-button {
  width: 317px;
  height: 56px;
}

.speciality-input-l {
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid white;
  width: 205px !important;
  // width: 16vw !important;
  @include responsive(680px, major) {
    width: 35vw !important;
  }
}

.speciality-input-r {
  border-radius: 0px 8px 8px 0px;
  width: 180px !important;
  // width: 15vw !important;
  @include responsive(680px, major) {
    width: 38vw !important;
  }
}

.actuation-input-l {
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid white;
  width: 205px !important;
  // width: 16vw !important;
  @include responsive(680px, major) {
    width: 35vw !important;
  }
}

.actuation-input-r {
  border-radius: 0px 8px 8px 0px;
  // width: 207px;
  width: 180px !important;
  @include responsive(680px, major) {
    width: 38vw !important;
  }
}

.garbage-icon_inside {
  @include responsive(680px, major) {
    left: 30vw;
  }
}

.std-button:disabled {
  background: #e0e0e0 !important;
  color: #828282 !important;
  border: none;
}

.p-my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.btn-rqe {
  width: 288px !important;
  height: 50px;
}
</style>
